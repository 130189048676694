<template>
  <div class="padding has-background-white">
    <div
      v-if="isLoading"
      class="loading has-text-centered"
    >
      <img
        src="@/assets/img/s2m-loader-big.gif"
        alt="loader"
        title="loader"
      />
    </div>
    <div v-if="!isLoading && isInvalid">
      <h3 class="title is-3">Hello!</h3>
      <div class="content mb-6">
        <p>I'm sorry, but your confirmation code is not valid anymore. This can happen when:</p>

        <ul>
          <li>You already activated your Passport earlier with pin code or clicking on the activation link in de registration confirmation email; or</li>
          <li>Your confirmation code has been expired.</li>
        </ul>
      </div>

      <div class="title is-3">How can I proceeded?</div>
      <div class="content mb-6">
        <p>
          In both cases you go to the login page where you can do the following actions:
        </p>

        <ul>
          <li><strong>I already activated my Passport</strong><br />
            In this case you can login with your email address and password.</li>
          <li><strong>I did not activated my Passport</strong><br />
            If you did not activate your Passport you can do this by set a new password. To do this you click on '<strong>Request new password</strong>' on the login page and follow the steps.</li>
        </ul>
      </div>

      <router-link
        tag="button"
        :to="{name: 'Login'}"
        class="button is-normal is-primary"
      >
        Go to login
      </router-link>
    </div>
  </div>
</template>

<script>
import { eraseCookie, getCookie, setCookie } from '../utils/browserStorage'
import { mapGetters } from 'vuex'
import profileProvider from '../providers/profile'

export default {
  data() {
    return {
      confirmToken: this.$route.params.confirmToken,
      isInvalid: false,
      isLoading: true
    }
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'getIsLoggedIn',
      isInitiated: 'getIsInitiated'
    }),
  },

  watch: {
    isInitiated: {
      immediate: true,
      handler: function(val) {
        if (val && this.isLoggedIn) {
          this.$router.push({ name: `Dashboard` })
        }
        else if (val) {
          this.confirm()
        }
      }
    },
  },

  created() {
    window.scrollTo(0, 0)
  },

  methods: {
    confirm() {
      profileProvider.methods.confirmProfile(this.confirmToken)
        .then(response => {
          setCookie('mt', response.data.MachineToken, 100, true)
          this.$store.dispatch('saveProfileToken', response.data.Token)
          this.$emit('isLoggedIn')
          this.$store.commit('setIsFirstRun', true)
          this.$router.push({ name: 'Dashboard' })
        })
        .catch(e => {
          this.isInvalid = true
        })
        .then(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  min-width: 200px;
}
</style>